<script>
export default {
  name: 'ProductCard'
}
</script>

<template>
<div class="tw-w-full tw-flex tw-justify-center">
  <div class="main-body">
    <div><p>For Commuters</p> <div class="ellipse"><img src="@/assets/icons/Npod-logo.svg"></div></div>

    <v-spacer></v-spacer>
    <p>For Business</p>
  </div>
</div>
</template>

<style scoped lang="scss">
.main-body{
  width: 800px;
  height: 206px;
  background: #FCFDFF;
  border: solid red;
}
.ellipse{
  border-radius: 50%;
  background:
      #E3F0FF;
}
</style>